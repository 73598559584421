<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <!-- <b-row class="mb-2">
          <b-col cols="12">
            <h2 class="font-weight-bolder">
              {{ title }}
            </h2>
          </b-col>
        </b-row> -->
        <b-row>
          <b-col
            v-if="hasExport || searchEnabled"
            cols="12"
            lg="12"
            :class="`mb-2 d-flex flex-row  align-items-center ${
              hasExport || customButton
                ? 'justify-content-between'
                : ' justify-content-end'
            }`"
          >
            <div
              v-if="hasExport || customButton"
              class="d-flex flex-row align-items-end w-50"
            >
              <div v-if="customButton">
                <b-button
                  variant="gradient-primary"
                  class="mr-1"
                  @click.prevent="$emit('customButton')"
                >
                  <span class=""> {{ customButton.label }} </span>
                  <feather-icon
                    v-if="customButton.icon"
                    :icon="customButton.icon"
                    size="14"
                    class="text-white ml-1"
                  />
                </b-button>
              </div>
              <div v-if="hasExport">
                <b-button-group :disabled="exporting">
                  <b-button disabled variant="outline-danger">
                    <b-spinner v-if="exporting" class="mr-50 p-0" small />
                    <feather-icon v-else icon="DownloadIcon" class="mr-50" />
                    Export
                  </b-button>
                  <b-dropdown variant="outline-danger">
                    <b-dropdown-item
                      v-for="(type, index) in exportType"
                      :key="index"
                      @click.prevent="$emit('handleExport', type.value)"
                    >
                      {{ type.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-button-group>
              </div>
            </div>
            <div v-if="searchEnabled" class="w-30">
              <b-input-group>
                <b-form-input v-model="search" placeholder="Search" />
                <b-input-group-append>
                  <b-input-group-text class="bg-primary text-white">
                    Search
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
          <b-col cols="12">
            <vue-good-table
              ref="my-table"
              @on-selected-rows-change="selectionChanged"
              style-class="vgt-table striped"
              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: paginationEnabled,
                perPage: pageLength,
              }"
              :search-options="{
                enable: searchEnabled,
                externalQuery: search,
              }"
              :sort-options="{
                enable: true,
              }"
              :select-options="{
                enabled: checkboxEnabled,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: false, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
            >
              <div slot="emptystate" class="text-center">
                <span v-if="!isLoading">No Data Found</span>
                <b-spinner v-else variant="primary" />
              </div>
              <template slot="table-row" slot-scope="props">
                <slot :props="props" />
              </template>
              <!-- pagination -->
              <template
                v-if="paginationEnabled"
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap p-1">
                  <div class="d-flex align-items-center">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '20', '50']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="selected-row-actions">
                <b-button
                  v-if="this.$route.path === '/inventory'"
                  variant="danger"
                  @click="deleteItem()"
                >
                  Delete
                </b-button>
                <template v-else>
                  <b-button variant="primary" class="mr-2" v-b-modal.modal-1>
                    Change Delivery Date
                  </b-button>
                  <b-button
                    variant="primary"
                    @click="updateBulkStatus()"
                    v-if="this.$route.name !== 'Outlet-tracker'"
                  >
                    Update Status
                  </b-button>
                </template>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-modal ref="modal-1" title="Change Delivery Date" id="modal-1">
      <div class="d-flex justify-content-center align-items-center">
        <b-form-group class="d-flex justify-content-center align-items-center">
          <flat-pickr
            v-model="dateFilter"
            :config="{
              dateFormat: 'Y-m-d',
              defaultDate: 'today',
              minDate: 'today',
            }"
            class="form-control"
            placeholder="Choose Date"
            readonly="readonly"
          />
        </b-form-group>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="updateBulkDeliveryDate()"
          >Confirm</b-button
        >
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "MyTable",
  components: {
    flatPickr,
    VueGoodTable,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    columns: {
      required: true,
      type: Array,
    },
    rows: {
      required: true,
      type: Array,
    },
    isLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    customButton: {
      type: Object,
      default: null,
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    paginationEnabled: {
      type: Boolean,
      default: true,
    },
    checkboxEnabled: {
      type: Boolean,
      default: false,
    },
    searchEnabled: {
      type: Boolean,
      default: true,
    },
    hasExport: {
      type: Boolean,
      default: false,
    },
    exporting: {
      type: Boolean,
      default: false,
    },
    exportType: {
      type: Array,
      default: () => [
        { value: "xlsx", text: "Excel" },
        { value: "csv", text: "CSV" },
      ],
    },
  },
  data() {
    return {
      search: "",
      pageLength: 10,
      selectedId: [],
      selectedIndex: [],
      dateFilter: "",
      optionsStatus: {
        Active: "Active",
        "In Warehouse": "In Warehouse",
        "Arrived At Branch": "Arrived At Branch",
        "Out For Delivery": "Out For Delivery",
        Delivered: "Delivered",
        "Close Store": "Close Store",
        "Second Delivery": "Second Delivery",
        "Assigned for Last Mile": "Assigned for Last Mile",
      },
    };
  },
  methods: {
    selectionChanged() {
      this.selectedId = [];
      this.selectedIndex = [];
      this.$refs["my-table"].selectedRows;
      for (let x = 0; x < this.$refs["my-table"].selectedRows.length; x++) {
        this.selectedId.push(this.$refs["my-table"].selectedRows[x].id);
        this.selectedIndex.push(
          this.$refs["my-table"].selectedRows[x].originalIndex
        );
      }
    },
    updateBulkDeliveryDate() {
      var updateStatus = {};
      updateStatus.orders = this.selectedId;
      updateStatus.delivery_date = this.dateFilter;
      this.$http
        .post(`massChangeDeliveryDate`, updateStatus)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status == true) {
              this.$swal({
                title: "Status Delivery Updated !",
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$refs["modal-1"].hide();
              this.$emit("switchFilter");
            } else {
              this.$swal({
                title: "Status Delivery Error !",
                text: response.data.message,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          }
        });
    },
    deleteItem() {
      this.$emit('deleteItems', this.selectedId);
    },
    updateBulkStatus() {
      const reasons = this.optionsStatus;
      this.$swal({
        title: "Update Status",
        input: "select",
        inputOptions: reasons,
        inputPlaceholder: "Select Status",
        inputAttributes: {
          "aria-label": "Select Status",
        },
        inputValidator: (value) => !value && "Please give a value",
        allowOutsideClick: true,
        confirmButtonText: "SUBMIT",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          var updateStatus = {};
          updateStatus.orders = this.selectedId;
          updateStatus.status = result.value;
          this.$http.post(`massChangeStatus`, updateStatus).then((response) => {
            if (response.status === 200) {
              if (response.data.status == true) {
                this.$swal({
                  title: "Status Delivery Updated !",
                  text: response.data.message,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                this.$emit("switchFilter");
              } else {
                this.$swal({
                  title: "Status Delivery Error !",
                  text: response.data.message,
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
